import axios from "axios";
import i18n from "@/plugins/i18n";

const http = axios.create({
  baseURL: `${process.env.VUE_APP_API_URL}/api`,
  timeout: 100000,
});
http.defaults.params = {};

http.interceptors.response.use(
  function (response) {
    let lang = i18n.locale;
    if (lang === "kz") {
      lang = "kk";
    }

    if (lang === "kk") {
      response.data?.data.forEach((e) => {
        e.attributes?.skillaz_vacancies?.data.forEach((skillaz) => {
          const localeAttributes =
            skillaz.attributes.vacancy.data.attributes.localizations.data[0]
              .attributes;
          if (localeAttributes) {
            for (const [key, val] of Object.entries(localeAttributes)) {
              if (key !== "id") {
                skillaz.attributes.vacancy.data.attributes[key] = val;
              }
            }
          }
        });
      });
      return response;
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default http;
