<template>
  <div v-if="vacancy" class="full-vacancy">
    <div class="container">
      <div class="full-vacancy__nav">
        <locale-router-link to="" class="crumbs-link main-page-link">
          {{ $t("home") }}</locale-router-link
        >
        <locale-router-link
          to="career"
          class="crumbs-link magnum-career-link"
          >{{ $t("сareerMagnum") }}</locale-router-link
        >
        <a class="crumbs-link magnum-career-full-link">{{
          getConditions(vacancy)
        }}</a>
      </div>

      <h1 class="full-vacancy__title" @click="sendSkillaz">
        {{ getConditions(vacancy) }}
      </h1>

      <div class="full-vacancy__experience">
        {{ $t("workExperience") }} {{ vacancy.attributes.required_experience }}
      </div>

      <!-- <div class="full-vacancy__tags">
        <div class="full-vacancy__tag">
          {{ vacancy.attributes.job_type.data.attributes.title }}
        </div>
      </div> -->

      <div class="full-vacancy__info">
        <div class="full-vacancy__descr">
          <!-- item 1 -->
          <div
            v-if="vacancy.attributes.todo"
            class="full-vacancy__item-wrapper"
          >
            <h3 class="full-vacancy__item-title">{{ $t("inOurTeam") }}</h3>
            <vue-markdown
              class="full-vacancy__list"
              :source="vacancy.attributes.todo"
            >
              {{ vacancy.attributes.todo }}
            </vue-markdown>
          </div>

          <!-- item 2 -->
          <div v-if="getKeySkills" class="full-vacancy__item-wrapper">
            <h3 class="full-vacancy__item-title">{{ $t("isImportant") }}</h3>

            <div class="full-vacancy__list">
              <ul>
                <li
                  v-for="skills in vacancy.attributes.key_skills"
                  :key="skills.id"
                >
                  {{ skills.title }}
                </li>
              </ul>
            </div>
          </div>

          <!-- item 3 -->
          <div
            v-if="vacancy.attributes.job_description"
            class="full-vacancy__item-wrapper"
          >
            <h3 class="full-vacancy__item-title">
              {{ $t("weExpected") }}
            </h3>
            <vue-markdown
              class="full-vacancy__list"
              :source="vacancy.attributes.job_description"
            >
            </vue-markdown>
          </div>

          <!-- item 4 -->
          <div
            v-if="vacancy.attributes.will_be_plus"
            class="full-vacancy__item-wrapper"
          >
            <h3 class="full-vacancy__item-title">
              {{ $t("isPlus") }}
            </h3>

            <vue-markdown
              class="full-vacancy__list"
              :source="vacancy.attributes.will_be_plus"
            >
            </vue-markdown>
          </div>

          <!-- item 5 -->
          <div
            v-if="vacancy.attributes.offer"
            class="full-vacancy__item-wrapper"
          >
            <h3 class="full-vacancy__item-title">
              {{ $t("ourAdvantage") }}
            </h3>

            <vue-markdown
              class="full-vacancy__list"
              :source="vacancy.attributes.offer"
            >
              {{ vacancy.attributes.offer }}
            </vue-markdown>
          </div>
        </div>
        <validation-observer v-if="!isShowSkillazModal" ref="formObserver">
          <form
            ref="fullVacancyForm"
            class="full-vacancy__form"
            @submit.prevent="sendForm"
          >
            <h2 class="full-vacancy__form-title">
              {{ $t("applyForJob") }}
            </h2>
            <div class="full-vacancy__input-wrapper">
              <vacancy-form-select
                class="vacancy-city__select"
                id="vacancies__select"
                v-model="userCity"
                v-if="skillazCities.length"
                :items="skillazCities"
                :firstSelect="firstCity"
                placeholder="formCity"
                :field="getCityTranslate"
                getField="object"
              />
              <div v-else class="loader-mini__container">
                <div class="loader-mini"></div>
              </div>

              <span v-if="!isSelectedVacancy" class="full-vacancy__span"
                >Выберите вакансию</span
              >
              <div :key="firstVacancy">
                <vacancy-form-select
                  :key="userCity"
                  id="vacancies__select"
                  v-model="skillazVacancy"
                  v-if="vacanciesSelect.length"
                  :items="vacanciesSelect"
                  :firstSelect="firstVacancy"
                  placeholder="vacancyPosition"
                  field="conditions"
                  getField="object"
                />
              </div>

              <!-- <div v-else class="loader-mini__container">
                <div class="loader-mini"></div>
              </div> -->
              <validation-provider v-slot="{ errors }" rules="required">
                <span v-if="isShowValidate" class="full-vacancy__span">{{
                  errors[0] === "required" ? $t("enterName") : ""
                }}</span>
                <input
                  v-model="userName"
                  type="text"
                  :placeholder="$t('name')"
                  class="full-vacancy__input"
                />
              </validation-provider>
              <validation-provider v-slot="{ errors }" rules="required">
                <span v-if="isShowValidate" class="full-vacancy__span"
                  >{{ errors[0] === "required" ? $t("enterSurname") : "" }}
                </span>
                <input
                  v-model="userSurname"
                  type="text"
                  :placeholder="$t('surname')"
                  class="full-vacancy__input"
                />
              </validation-provider>
              <validation-provider v-slot="{ errors }" rules="email">
                <span class="full-vacancy__span">{{
                  errors[0] === "email" ? $t("emailIncorrectly") : ""
                }}</span>
                <input
                  v-model="userEmail"
                  :placeholder="$t('emailRequired')"
                  class="full-vacancy__input"
                />
              </validation-provider>
              <validation-provider v-slot="{ errors }" rules="required">
                <span v-if="isShowValidate" class="full-vacancy__span">{{
                  errors[0] === "required" ? $t("enterPhone") : ""
                }}</span>
                <div class="full-vacancy__box">
                  <input
                    v-model="userPhone"
                    v-mask="'(###)###-##-##'"
                    type="text"
                    :placeholder="$t('phoneNumber')"
                    class="full-vacancy__input phone"
                    :class="{ 'is-focus': isShowPhoneSpan }"
                    @focus="focusPhone"
                  />
                  <span
                    v-if="isShowPhoneSpan"
                    class="full-vacancy__input-number"
                  >
                    +7
                  </span>
                </div>
              </validation-provider>
              <validation-provider v-slot="{ errors }" rules="required">
                <span v-if="isShowValidate" class="full-vacancy__span">{{
                  errors[0] === "required" ? $t("enterBirthdate") : ""
                }}</span>
                <input
                  v-model="userBirthdate"
                  v-mask="'##-##-####'"
                  :placeholder="$t('formBirthdate')"
                  class="full-vacancy__input full-vacancy__input--last-child"
                />
                <!-- v-if="$isMobile()" -->
                <!-- <input
                  v-else
                  v-model="userBirthdate"
                  type="date"
                  :placeholder="$t('formBirthdate')"
                  class="full-vacancy__input full-vacancy__input--last-child"
                  pattern="[0-9]{4}-[0-9]{2}-[0-9]{2}"
                /> -->
              </validation-provider>
            </div>
            <button
              class="full-vacancy__button"
              :class="{ disabled: isBtnDisabled }"
              type="submit"
              :disabled="isBtnDisabled"
            >
              {{ $t("send") }}
            </button>
          </form>
        </validation-observer>
        <div v-else class="modal__skillaz">
          <button
            class="modal__skillaz-btn"
            @click="isShowSkillazModal = false"
          >
            Откликнуться на вакансию
          </button>
          <p class="modal__skillaz-text">
            вы уже подавали отклик на эту или иные вакансии, ожидайте звонка от
            наших HR специалистов
          </p>
        </div>
        <!-- Info -->
      </div>

      <div class="full-vacancy__contacts">
        <h3>{{ $t("vacancyContacts.title") }}</h3>
        <div class="full-vacancy__contacts-wrapper">
          <a href="tel:7766" class="full-vacancy__contacts-number"
            >{{ $t("vacancyContacts.number") }}
          </a>
          <a href="mailto:job@magnum.kz" class="full-vacancy__contacts-email"
            >job@magnum.kz</a
          >
        </div>
        <div class="hashtags">
          <div class="hashtag">#могувмагнум</div>
          <div class="hashtag">#магнуммен</div>
        </div>
      </div>

      <div v-if="otherVacancies.length" class="full-vacancy__other">
        <h3 class="full-vacancy__other-title">{{ $t("otherVacancies") }}</h3>
        <swiper
          :key="otherVacancies"
          ref="swiperVacancyRef"
          :options="swiperOption"
        >
          <swiper-slide v-for="(item, index) in otherVacancies" :key="index">
            <locale-router-link
              :to="`vacancy/${item.attributes.vacancy.data.id}`"
              class="full-vacancy__other-item"
            >
              <h6>{{ getConditions(item.attributes.vacancy.data) }}</h6>
              <p>
                {{ $t("workExperience") }}
                {{ getRequiredExperience(item.attributes.vacancy.data) }}
              </p>
            </locale-router-link>
          </swiper-slide>
        </swiper>
        <div class="slider-pagination-wrapper">
          <div
            class="pagination__custom-production swiper-pagination"
            slot="pagination"
          ></div>
        </div>
        <button
          v-if="isShowArrows"
          @click="prevSlide"
          class="swiper-button-prev"
        >
          <img src="@/assets/img/icons/arrow-home.svg" alt="" />
        </button>
        <button
          v-if="isShowArrows"
          @click="nextSlide"
          class="swiper-button-next"
        >
          <img src="@/assets/img/icons/arrow-home.svg" alt="" />
        </button>
      </div>

      <!-- modals -->
      <div
        v-if="isInvalidDate"
        class="popup-overlay"
        @click="isInvalidDate = false"
      >
        <div class="popup" @click.stop>
          <div class="full-vacancy__modal">
            <button
              class="full-vacancy__modal-close"
              @click="isInvalidDate = false"
            ></button>
            <div class="full-vacancy__modal-title">
              {{ $t("invalidDateTitle") }}
            </div>
            <div class="full-vacancy__modal-subtitle">
              {{ $t("invalidDateText") }}
            </div>
            <div class="full-vacancy__modal-img">
              <img
                src="@/assets/img/magnum-career/bitten-donut.svg"
                alt="modal image"
              />
            </div>
            <button
              class="full-vacancy__modal-btn"
              @click="isInvalidDate = false"
            >
              {{ $t("close") }}
            </button>
          </div>
        </div>
      </div>
      <div v-if="isSuccessReply" class="popup-overlay" @click="closeModal">
        <div class="popup" @click.stop>
          <div class="full-vacancy__modal">
            <button
              class="full-vacancy__modal-close"
              @click="closeModal"
            ></button>
            <div class="full-vacancy__modal-title">
              {{ $t("receivedYourApplication") }}
            </div>
            <div class="full-vacancy__modal-subtitle">
              {{ $t("ThankFeedback") }}
            </div>
            <div class="full-vacancy__modal-img">
              <img
                src="@/assets/img/magnum-career/modal.svg"
                alt="modal image"
              />
            </div>
            <button class="full-vacancy__modal-btn" @click="closeModal">
              {{ $t("fine") }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getVacancy, sendReply, getFilteredVacancies } from "@/api/vacancy";
import { getSkillazCity, sendSkillazReply } from "@/api/skillaz";

import VueMarkdown from "vue-markdown";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { mapState } from "vuex";
export default {
  name: "FullVacancy",
  components: {
    VacancyFormSelect: () =>
      import("@/components/vacancy/VacancyFormSelect.vue"),
    VueMarkdown,
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      vacancy: null,
      vacancies: [],
      firstVacancy: null,
      vacanciesSelect: [],
      isSelectedVacancy: true,
      userName: "",
      userSurname: "",
      userPhone: "",
      userEmail: "",
      userCity: null,
      userVacancy: "",
      userBirthdate: "",

      isBtnDisabled: false,

      isSuccessReply: false,
      isShowValidate: false,
      pagination: {
        page: 1,
        pageSize: 20,
        isLastPage: false,
      },
      swiperOption: {
        slideTo: 1,
        spaceBetween: 20,
        loop: true,
        loopedSlides: null,
        loopAdditionalSlides: 0,
        autoplay: {
          delay: 5000,
        },
        pagination: {
          el: ".pagination__custom-production",
          clickable: true,
        },
        breakpoints: {
          1024: {
            slidesPerView: 4,
          },
          600: {
            slidesPerView: 3,
          },
          450: {
            slidesPerView: 2,
          },
          300: {
            slidesPerView: 1,
          },
        },
      },

      otherVacancies: [],
      //skilazz
      skillazVacancies: [],
      skillazVacancy: null,
      skillazVacancyCities: [],
      skillazCities: [],
      firstCity: null,
      isShowSkillazModal: false,
      isShowPhoneSpan: false,
      isShowArrows: true,

      isInvalidDate: false,
    };
  },
  computed: {
    ...mapState("vacancyModule", ["filteredVacancy"]),
    ...mapState("cityModule", { cities: "data", selectedCity: "selectedCity" }),
    swiper() {
      return this.$refs?.swiperVacancyRef;
    },
    isCities() {
      return !!this.vacancy.attributes?.cities?.data?.length;
    },
    getSelectCity() {
      if (this.$i18n.locale === "ru") {
        if (this.userCity) {
          return this.userCity.attributes.label_ru;
        }
        return this.firstCity.attributes.label_ru;
      } else {
        if (this.userCity) {
          return this.userCity.attributes.label_kz;
        }
        return this.firstCity.attributes.label_kz;
      }
    },
    getCityTranslate() {
      if (this.$i18n.locale === "ru") {
        return "label_ru";
      } else {
        return "label_kz";
      }
    },
  },
  watch: {
    "$route.params.id": {
      deep: true,
      handler() {
        getVacancy(Number(this.$attrs.id)).then((vacancy) => {
          this.vacancy = vacancy;
          this.otherVacancies = this.vacanciesSelect.filter(
            (e) => e.attributes.vacancy.data.id !== this.vacancy?.id
          );
          this.firstVacancy = this.vacanciesSelect.find(
            (e) => e.attributes.vacancy.data.id === this.vacancy?.id
          );
        });
        getFilteredVacancies(
          { jobType: null, position: null },
          this.pagination,
          Number(this.$attrs.id)
        ).then((vacancies) => {
          this.vacancies = vacancies.data;
        });
      },
    },
    userCity: {
      handler() {
        this.firstVacancy = null;
        this.vacanciesSelect = this.userCity.attributes.skillaz_vacancies.data;
        this.otherVacancies = this.vacanciesSelect.filter(
          (e) => e.attributes.vacancy.data.id !== this.vacancy?.id
        );
        if (this.otherVacancies.length < 5) {
          this.swiperOption.loop = false;
          this.isShowArrows = false;
        } else {
          this.swiperOption.loop = true;
          this.isShowArrows = true;
        }
        this.skillazVacancy = null;
      },
    },
    otherVacancies: {
      handler() {
        console.log("lol", this.otherVacancies);
      },
    },
    skillazVacancy: {
      handler() {
        const id = this.skillazVacancy?.attributes?.vacancy?.data?.id;
        if (this.skillazVacancy !== null) {
          if (id !== this.vacancy.id) {
            this.$router.push({
              params: { id: id, noScroll: true },
              query: { city_id: this.selectedCity.id || 2 },
            });
          }
          this.isSelectedVacancy = true;
        }
      },
    },
  },
  created() {
    getVacancy(Number(this.$attrs.id)).then((vacancy) => {
      this.vacancy = vacancy;

      this.initLocaleVacancy();
    });
    getFilteredVacancies(
      { jobType: null, position: null },
      this.pagination,
      Number(this.$attrs.id),
      this.filteredVacancy
    ).then((vacancies) => {
      this.vacancies = vacancies.data;
    });
    getSkillazCity().then((res) => {
      this.skillazCities = res;
      this.firstCity = this.skillazCities.find(
        (e) => e.id === this.selectedCity.id
      );
      this.vacanciesSelect = this.firstCity.attributes.skillaz_vacancies.data;
      this.otherVacancies = this.vacanciesSelect.filter(
        (e) => e.attributes.vacancy.data.id !== this.vacancy?.id
      );
      if (this.otherVacancies.length < 5) {
        this.swiperOption.loop = false;
        this.isShowArrows = false;
      } else {
        this.swiperOption.loop = true;
        this.isShowArrows = true;
      }
      this.firstVacancy = this.vacanciesSelect.find(
        (e) => e.attributes.vacancy.data.id === this.vacancy?.id
      );
      this.isSelectedVacancy = true;
    });
  },
  methods: {
    initLocaleVacancy() {
      if (this.$i18n.locale === "kz") {
        this.vacancy.attributes.cities.data =
          this.vacancy?.attributes.cities.data?.map((c) => {
            c.attributes = c.attributes.localizations.data[0].attributes;
            return c;
          });
        this.vacancy.attributes.job_type.data.attributes =
          this.vacancy.attributes.job_type.data.attributes.localizations?.data[0]?.attributes;
        getVacancy(this.vacancy.related_id).then((vacancyKz) => {
          this.vacancy.attributes.key_skills = vacancyKz.attributes.key_skills;
        });
      }
    },
    prevSlide() {
      this.swiper?.swiperInstance.slidePrev();
    },
    nextSlide() {
      this.swiper?.swiperInstance.slideNext();
    },
    sendForm() {
      this.isShowValidate = true;
      if (this.skillazVacancy || this.firstVacancy) {
        this.isSelectedVacancy = true;
        this.$refs.formObserver.validate().then(async (success) => {
          if (!success) {
            return;
          }
          this.isBtnDisabled = true;
          const user = {
            name: this.userName,
            surname: this.userSurname,
            phone: `+7 ${this.userPhone}`,
            email: this.userEmail,
            city: this.getSelectCity,
            vacancy: this.skillazVacancy
              ? this.skillazVacancy?.attributes?.vacancy?.data?.id
              : this.firstVacancy?.attributes?.vacancy?.data?.id,
          };
          console.log(user);
          const that = this;

          try {
            await sendReply({ data: user });
            this.sendSkillaz();
            this.isSuccessReply = true;
            that.$nextTick(() => {
              that.$refs.fullVacancyForm.reset();
            });
            this.userName = "";
            this.userSurname = "";
            this.userPhone = "";
            this.userEmail = "";
            this.userCity = "";
            this.isShowValidate = false;
            this.isBtnDisabled = false;
          } catch (e) {
            console.log(e.response);
          }
        });
      } else {
        this.isSelectedVacancy = false;
      }
    },
    getKeySkills() {
      return this.vacancy?.attributes?.key_skills?.length;
    },
    getConditions(item) {
      return item?.attributes?.conditions;
    },
    getRequiredExperience(item) {
      return item?.attributes?.required_experience;
    },

    closeModal() {
      this.isSuccessReply = false;
    },

    sendSkillaz() {
      let userBirthdate = this.userBirthdate.split("-");
      const skillaz = {
        name: this.userName,
        surname: this.userSurname,
        email: this.userEmail,
        phone: `+7 ${this.userPhone}`,
        city: this.getSelectCity,
        birthdate: `${userBirthdate[2]}-${userBirthdate[1]}-${userBirthdate[0]}`,
        skillaz_vacancy_id: this.skillazVacancy
          ? this.skillazVacancy?.attributes?.skillaz_vacancy_id
          : this.firstVacancy?.attributes?.skillaz_vacancy_id,
      };

      sendSkillazReply({ data: { ...skillaz } })
        .then((res) => {
          if (res.status === 201 && !res.data.IsOk) {
            this.isShowSkillazModal = true;
          }
        })
        .catch((error) => {
          if (error.response.data.error.message === "Invalid date") {
            this.isInvalidDate = true;
          }
        });
    },
    focusPhone() {
      this.isShowPhoneSpan = true;
    },
  },
};
</script>

<style lang="scss" scoped>
#vacancies__select {
  margin-bottom: 15px;
}

.vacancy-city__select {
  z-index: 25;
}

.full-vacancy__button {
  &.disabled {
    opacity: 0.5;
  }
}

.full-vacancy li {
  list-style-type: initial !important;
}
.swiper-button-next {
  left: auto;
  right: -70px !important;
  transform: translateY(50%) rotate(180deg);
}
.swiper-button-prev {
  left: -70px !important;
  transform: translateY(50%) rotate(0);
}

.slider-pagination-wrapper {
  display: none;
  bottom: -20px;
}
.swiper-wrapper {
  align-items: center;
}

@media (max-width: 1300px) {
  .swiper-button-next {
    right: -33px !important;
  }
  .swiper-button-prev {
    left: -33px !important;
  }
}

@media (max-width: 600px) {
  .slider-pagination-wrapper {
    display: flex;
  }
}

.popup-overlay {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba($color: #000000, $alpha: 0.4);
  z-index: 999;
}
.popup {
  transition: all 0.3s ease-in-out;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  &__header {
    width: 100%;
    justify-content: flex-end;
  }
  &__button-close {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 44px;
    height: 44px;
    // background: transparent url('~@/assets/images/icons/close.svg') no-repeat;
    background-position: center center;
    border: none;
  }
  .hide {
    display: none;
  }
}
</style>
