import axios from "@/plugins/axios";
import axiosUninterseptor from "@/plugins/axiosUninterseptor";
import axiosVacancy from "@/plugins/axiosVacancy";

import qs from "qs";

export const getSkillazVacancyById = async (id) => {
  const query = qs.stringify({
    filters: {
      skillaz_cities: {
        id: { $eq: id },
      },
    },
    populate: {
      vacancy: {
        populate: "*",
        localizations: "*",
      },
      skillaz_cities: "*",
      localizations: "*",
    },
  });
  const response = await axios.get(`/skillaz-vacancies?${query}`);
  return response?.data?.data;
};

export const getSkillazCity = async () => {
  const query = qs.stringify({
    populate: {
      skillaz_vacancies: {
        populate: {
          vacancy: {
            populate: {
              localizations: "*",
            },
          },
        },
      },
    },
  });

  const response = await axiosVacancy.get(`/skillaz-cities?${query}`);
  return response?.data?.data;
};

export const sendSkillazReply = async (data) => {
  const response = await axiosUninterseptor.post(`/skillaz-replies`, data);
  return response;
};
